<template>
  <v-dialog v-model="delete_alert_modal" width="550">
    <v-card class="pa-0">
      <v-alert prominent text type="error" class="pa-3 mb-0">
        <v-card-title class="text-h5">
          You can't delete this bank account
        </v-card-title>
        <v-card-text
          >Transactions have already been added to this bank account. They must
          be removed before it can be deleted.
        </v-card-text>
      </v-alert>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "BankAccountsDeleteDisableDialog",
  props: ["delete_alert_modal"],
  data() {
    return {};
  },
};
</script>
